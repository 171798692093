import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {DataService} from '../data.service';

declare var $: any;
declare var toastr: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  countryList: any;
  stateList: any;
  cityList: any;
  riderRequest = {
    first_name: '',
    last_name: '',
    phone: '',
    cnic: null,
    email: '',
    password: '',
    permanent_address: '',
    temporary_address: '',
    country: '',
    state: '',
    city: '',
    status: '',
    rider_profile_image: '',
    rider_bike_image: '',
    rider_cnic_front_image: '',
    rider_cnic_back_image: '',
  };
  constructor(private ngxService: NgxUiLoaderService, private dataService: DataService,) { }

  bannerForm(valueBannerForm: NgForm) {
    console.log('valueBannerForm', valueBannerForm.value);
  }

  ngOnInit() {
    console.log('ngxService', this.ngxService);
    $('form').parsley();
    this.getCountries();
    $('#businessPhone1').intlTelInput({
      utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/12.1.9/js/utils.js',
      autoPlaceholder: true,
      preferredCountries: ['pk'],
      preventInvalidNumbers: true,
      preventInvalidDialCodes: true,
      formatOnDisplay: false,
      separateDialCode: true,
    });
  }
  getDataUrl(img){
    var canvas = document.createElement('canvas')
    var ctx = canvas.getContext('2d')

    canvas.width = img.width
    canvas.height = img.height
    ctx.drawImage(img, 0, 0)

    // If the image is not png, the format
    // must be specified here
    return canvas.toDataURL()
  }
  getCountries() {
    this.dataService.getCountryList().subscribe(
      (data) => {
        this.countryList = data;
      },
      (error) => {
      }
    );
  }
  changeCountry(name) {
    this.stateList = [];
    this.cityList = [];
    this.riderRequest.state = '';
    this.riderRequest.city = '';
    if ( name === '' ) {
      return;
    }
    console.log('sdfsd 1', this.countryList);
    this.stateList = this.countryList.filter(t => t.name === name)[0].states;
    console.log('sdfsd 1');
  }
  changeState(name) {
    this.cityList = [];
    this.riderRequest.city = '';
    if ( name === '' ) {
      return;
    }
    this.cityList = this.stateList.filter(t => t.name === name)[0].cities;
  }
  addRider() {
    this.getBaseUrl('profileImg');
    this.getBaseUrl('bikeImage');
    this.getBaseUrl('cnicImage1');
    this.getBaseUrl('cnicImage2');

    const form = $('#requestForm');
    form.parsley().validate();
    if (form.parsley().isValid()) {
      this.riderRequest.phone = $('#businessPhone1').intlTelInput('getNumber');
      this.ngxService.start();
      setTimeout( () => {
      this.dataService.addRiderRequest(this.riderRequest).subscribe(
        (data) => {
          toastr.success('Application submit successfully');
          this.riderRequest = {
            first_name: '',
            last_name: '',
            phone: '',
            cnic: null,
            email: '',
            password: '',
            permanent_address: '',
            temporary_address: '',
            country: '',
            state: '',
            city: '',
            status: '',
            rider_profile_image: '',
            rider_bike_image: '',
            rider_cnic_front_image: '',
            rider_cnic_back_image: '',
          };
          $('#profileImg').val('');
          $('#bikeImage').val('');
          $('#cnicImage1').val('');
          $('#cnicImage2').val('');
          $('body').removeClass('sidebarActive');
          this.ngxService.stop();
        },
        (error) => {
          this.ngxService.stop();
          toastr.error('Something went wrong');
        }
      );
      }, 3000 );
    }
  }

  getBaseUrl (id) {
    var contax = this;
    var file = document.getElementById(id)['files'][0];
    var reader = new FileReader();
    reader.onload = function(event) {
      setTimeout( () => {
        contax.setResult(event.target, id);
        // console.log(event.target.result);
      }, 1000);
    };
    reader.readAsDataURL(file);
  }
  setResult(target, id){
    if ( id === 'profileImg' ) {
      this.riderRequest.rider_profile_image = target.result;
    }
    if ( id === 'bikeImage' ) {
      this.riderRequest.rider_bike_image = target.result;
    }
    if ( id === 'cnicImage1' ) {
      this.riderRequest.rider_cnic_front_image = target.result;
    }
    if ( id === 'cnicImage2' ) {
      this.riderRequest.rider_cnic_back_image = target.result;
    }
  }
  prevent($event) {
  }

}
