import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  getCountryList() {
    return this.http.get(environment.apiAddress + 'api/v1/utilities/countries/',
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', 'Authorization': 'Bearer ' }) });
  }

  addRiderRequest(deliveryRequest): Observable <any> {
    console.log('deliveryRequest', deliveryRequest);
    return this.http.post( environment.apiAddress + 'api/v3/register_rider/', JSON.stringify({
        first_name: deliveryRequest.first_name,
        last_name: deliveryRequest.last_name,
        phone: deliveryRequest.phone,
        cnic: deliveryRequest.cnic,
        email: deliveryRequest.email,
        password: deliveryRequest.password,
        permanent_address: deliveryRequest.permanent_address,
        temporary_address: deliveryRequest.temporary_address,
        country: deliveryRequest.country,
        state: deliveryRequest.state,
        city: deliveryRequest.city,
        status: deliveryRequest.status,
        rider_profile_image: deliveryRequest.rider_profile_image,
        rider_bike_image: deliveryRequest.rider_bike_image,
        rider_cnic_front_image: deliveryRequest.rider_cnic_front_image,
        rider_cnic_back_image: deliveryRequest.rider_cnic_back_image,
      }),
      { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'x-api-key': '5f7af37cb35f5cd8', 'Authorization': 'Bearer ' }) });
  }

}
